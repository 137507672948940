import axios from 'axios';
import CONFIG from 'config';
import authService from 'redux/auth';
import { qaKpCmsIds } from 'redux/api/knowledgeAi/knowledgeAi';
import { getRandomString } from 'utils/string-mapper/string-mapper';
import { checkDurationAndReload, isAuthAttemptSet, setAuthAttemptTime } from 'utils/auth/auth';
import { actions as chatHistoryActions } from 'redux/api/chatHistory/chatHistory';
import { handleStyledStatusMessages } from 'utils/misc/misc';
import * as analytics from 'components/ChatBot/ChatBot.analytics';
import { processBoldMarkdown, processCustomMarkdown } from './utils';

export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  USER_MESSAGE: 'user_message',
  STATUS: CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE ? 'status_message' : 'status',
  TOOL_OUTPUT: 'tool_output',
  RETRY: 'retry',
  ERROR: 'error'
};

export const name = 'chat';

export const CHAT_SET_HISTORY_ID = 'CHAT_SET_HISTORY_ID';
export const CHAT_PENDING = 'CHAT_PENDING';
export const CHAT_REPLACE_MESSAGE = 'CHAT_REPLACE_MESSAGE';
export const CHAT_FAILURE = 'CHAT_FAILURE';
export const CHAT_SOURCES_SUCCESS = 'CHAT_SOURCES_SUCCESS';
export const CHAT_SOURCES_PENDING = 'CHAT_SOURCES_PENDING';
export const CHAT_SOURCES_FAILURE = 'CHAT_SOURCES_FAILURE';
export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';
export const SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS = 'SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS';
export const CHAT_STREAM_CHUNK = 'CHAT_STREAM_CHUNK';
export const CHAT_STREAM_NEW_MESSAGE = 'CHAT_STREAM_NEW_MESSAGE';
export const CHAT_SET_QUERY = 'CHAT_SET_QUERY';
export const CHAT_ADD_MESSAGE = 'CHAT_ADD_MESSAGE';
export const CHAT_DELETE_LATEST_ASSISTANT_MESSAGE = 'CHAT_DELETE_LATEST_ASSISTANT_MESSAGE';
export const SET_FETCH_CONTROLLER = 'SET_FETCH_CONTROLLER';
export const NEW_ERROR_STATUS = 'NEW_ERROR_STATUS';
export const REMOVE_FETCH_CONTROLLER = 'REMOVE_FETCH_CONTROLLER';
export const SET_SELECTED_ENGINE = 'SET_SELECTED_ENGINE';
export const START_NEW_CHAT = 'START_NEW_CHAT';
export const CHAT_STREAM_COMPLETED = 'CHAT_STREAM_COMPLETED';
export const CHAT_SET_REQUEST_ID = 'CHAT_SET_REQUEST_ID';
export const SET_CHAT_HISTORY_MESSAGE = 'SET_CHAT_HISTORY_MESSAGE';
export const CHAT_STREAM_CLOSED = 'CHAT_STREAM_CLOSED';
export const TIME_TO_FIRST_CHUNK = 'TIME_TO_FIRST_CHUNK';
export const UPDATE_STATUS_MESSAGES = 'UPDATE_STATUS_MESSAGES';
export const RESET_STATUS_MESSAGES = 'RESET_STATUS_MESSAGES';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const TOGGLE_WEB_SOURCES = 'TOGGLE_WEB_SOURCES';
export const RESET_SELECTED_TOOL = 'RESET_SELECTED_TOOL';
export const UPDATE_WEB_FILTERS = 'UPDATE_WEB_FILTERS';
export const CLEAR_WEB_FILTERS = 'CLEAR_WEB_FILTERS';
export const END_CHAT = 'END_CHAT';
export const SET_SELECTED_TOOL = 'SET_SELECTED_TOOL';
export const CHAT_SET_MESSAGE_SOURCES = 'CHAT_SET_MESSAGE_SOURCES';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  query: '',
  messages: [],
  hasSentInitialMessage: false,
  messageSources: {},
  fetchController: null,
  selectedEngine: 'gpt-4',
  chatHistoryId: null,
  isChatCompleted: false,
  isChatStreaming: false,
  timeToFirstChunk: 0,
  isChatStreamClosed: false,
  statusMessages: {},
  searchResults: {},
  selectedTool: [CONFIG.NAVI_TOOL_NAMES.KNOWLEDGE, CONFIG.NAVI_TOOL_NAMES.EXPERTS],
  dateFilters: { startDate: '', endDate: '', filterName: CONFIG.WEB_SEARCH_DATE_FILTERS[CONFIG.WEB_SEARCH_DATE_FILTERS.length-1] },
  domains: []
};

export const customMarkdownRegex = /(\[[0-9]{1,3}-[^\]]+\])/ig;

const errorContinueMessage = {
  'role': 'user',
  'content': 'Your previous message was cut off due to an error. Please respond seamlessly from where you left off.'
};

const isUsingAgentsinStaging = CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && CONFIG.ENVIRONMENT === 'stg';

const setHasSentInitialMessage = (hasSentInitialMessage) => {
  return { type: SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS, payload: hasSentInitialMessage };
};

const setChatMessageSources = (id, sources, documents) => (dispatch) => {
  try {
    if (!id) {
      throw new Error(`setChatMessageSources: Missing required parameters: id: ${id}, sources: ${sources}, documents: ${documents}`);
    }
    dispatch({ type: CHAT_SOURCES_PENDING, payload: { id } });

    const sourceDocs = [];
    if (sources && documents) {
      sources.forEach(source => {
        const doc = documents.find(doc => doc.kp_cms_id === source.id);
        if (doc) {
          sourceDocs.push(doc);
        } else {
          console.error('setChatMessageSources: Failed to find document, doc', id, 'sourceid', source.id);
        }
      });
      if (sourceDocs.length === 0) {
        throw new Error('setChatMessageSources: Failed to find any source documents');
      }
    }

    dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id, sources: sourceDocs, loading: sources.length === 0 } });
  } catch (error) {
    console.error('setChatMessageSources: Failed to set sources', error);
    dispatch({ type: CHAT_SOURCES_FAILURE, payload: { error, id } });
  }
};

const processMessageForMarkdown = (message) => {
  if(!message) {
    return message;
  }

  let messageContent = processBoldMarkdown(message?.content);
  const matches = message?.content?.match(customMarkdownRegex);

  matches?.forEach((markdownKey) => {
    [messageContent] = processCustomMarkdown(markdownKey, messageContent, message?.combinedToolOutput || {}, message?.toolGroup);
  });

  return {
    ...message,
    content: messageContent
  };
};

const streamChat = (selectedEngine, onChunk, retry = false, sendMessageAnalytics, forcedToolSelection = []) => async (dispatch, getState) => {
  let response;
  dispatch({ type: CHAT_PENDING });
  CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && dispatch({ type: RESET_STATUS_MESSAGES });
  const { 
    query: _query, 
    messages: streamMessages, 
    chatHistoryId = null, 
    selectedTool: [selectedTool], 
    domains, 
    dateFilters: { filterName } 
  } = getState()?.chat || {};
  
  if ((!_query || _query?.length === 0) && (!streamMessages || streamMessages?.length === 0)) {
    console.error('KNCHAT callChat: No query/messages to send');
    return null;
  }

  const requestId = getRandomString(20);
  dispatch(setRequestID(requestId));
  const lastMessage = streamMessages[streamMessages.length - 1];
  sendMessageAnalytics && sendMessageAnalytics(lastMessage?.id, getState().chat.requestId, CONFIG.LABELS.ENTER_CHAT, chatHistoryId, selectedTool, filterName, domains);
  try {
    const _messages = streamMessages.filter(m => !!m.content && (m.role === ROLES.USER || m.role === ROLES.ASSISTANT)).map(message => {
      return {
        role: message.role,
        content: message.content
      };
    });

    const accessToken = await authService.getAccessToken();

    const headers = new Headers();
    headers.append('accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('x-api-key', CONFIG.X_API_KEY);
    let body_json = {};
    if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
      body_json = {
        'agent_id': CONFIG.AGENT_ID,
        'consumer_id': 'KN',
        'request_id': requestId,
        'selected_tools': forcedToolSelection.length ? forcedToolSelection : getState().chat.selectedTool
      };
      const state = getState().chat;
      if(state.selectedTool.includes(CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH)) {
        let executionPayload = {};
        if (state.domains && state.domains.length > 0) {
          executionPayload = {
            ...executionPayload,
            'included_domains': state.domains
          };
        }
        if (state.dateFilters.startDate !== '') { 
          executionPayload = {
            ...executionPayload,
            'start_date': state.dateFilters.startDate,
            'end_date': state.dateFilters.endDate
          };
        }
        if (Object.keys(executionPayload).length > 0) {
          body_json = {
            ...body_json,
            'execution_payload': {
              'web': {
                [CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH]: executionPayload
              },
            }
          };
        }
      } 
    } else {
      body_json = {
        'gen_options': {
          'max_tokens': 1600,
          'stream': true
        },
        'request_id': requestId,
        'consumer_id': 'KN',
        'engine': selectedEngine
      };
    }
    if (getState().chat.chatHistoryId) {
      console.log('KNCHAT callChat: Using chatHistoryId', getState().chat.chatHistoryId);
      body_json.chat_history_id = getState().chat.chatHistoryId;
    }
    if (_query && _query.length > 0 && CONFIG.API_URL.GENAI_CHAT.includes('/v2/')) {
      if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
        body_json.input_query = retry ? errorContinueMessage.content.concat(',', _query) : _query;
      } else {
        body_json.query = retry ? errorContinueMessage.content.concat(',', _query) : _query;
      }
    }
    else {
      body_json.messages = retry ? _messages.concat(errorContinueMessage) : _messages;
    }
    const body = JSON.stringify(body_json);

    const fetchController = new AbortController();
    const requestOptions = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
      signal: fetchController.signal,
    };
    dispatch({ type: SET_FETCH_CONTROLLER, payload: fetchController });
    const startTime = new Date();
    // using fetch as axios doesn't support this type of stream
    try {
      const chatServiceURL = CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE ? CONFIG.API_URL.GENAI_AGENTS_EXECUTION : CONFIG.API_URL.GENAI_CHAT;
      response = await fetch(chatServiceURL, requestOptions);
      if (response.status === 403) {
        if (!isAuthAttemptSet()) setAuthAttemptTime();
        checkDurationAndReload(3);
      }
    } catch (error) {
      if (error === 'User clicked stop generating') {
        console.warn('Fetch aborted:', error);
      } else {
        console.error('Error occurred:', error);
        throw error;
      }
    }
    const reader = !fetchController.signal.aborted && response.body.pipeThrough(new window.TextDecoderStream()).getReader();

    let boldMarkdown = '';
    let boldMarkdownOpen = false;
    let waitForBoldMarkdown = false;

    let customMarkdown = '';
    let waitForCustomMarkdown = false;

    let contentCnt = 0;
    let chunkCnt = 0;

    let endedCleanly = false;
    let createdNewMessage = false;
    let shouldProcessMessage = true;

    let chatId = '';
    let chatHistoryId = 0;
    let processChunks = true;

    let rawSearchResults = [];
    let searchResultDocs = [];

    if(reader) {
      await dispatch({ type: CHAT_STREAM_COMPLETED, payload: false });
      await dispatch({ type: CHAT_STREAM_CLOSED, payload: false });

      let rawContent = '';
      let currentToolGroup = '';

      const toolGroups = [CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL, CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL, CONFIG.AGENTS_TOOL_GROUPS.NAVI_CHAT.TOOL, CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL]; 
      const toolGroupsStatus = {[toolGroups[0]]: {isFirstMessage: true}, [toolGroups[1]]: {isFirstMessage: true}, [toolGroups[2]]: {isFirstMessage: true}, [toolGroups[3]]: {isFirstMessage: true}, [toolGroups[4]]: {isFirstMessage: true}};

      let combinedToolOutput = '';
      let aggregatedCombinedToolOutput = {};
      let processingCombinedToolOutput = false;
      let combinedToolOutputToolCount = 0;
      let keys = [];

      while (processChunks) {
        const { value, done } = await reader.read();
        if (done) {
          const timeToComplete = new Date() - startTime;
          const timeToFirstChunk = getState().chat.timeToFirstChunk;
          console.warn(`KNCHAT done with ${contentCnt} chunks ${timeToComplete}ms; requestId: ${requestId};`);
          analytics.timeToCompleteChat(chatId, requestId, timeToFirstChunk, timeToComplete, chatHistoryId);
          // this stream closes here for short messages
          if (!CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
            await dispatch({ type: CHAT_STREAM_COMPLETED, payload: true });
            await dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
          }
          processChunks = false;
          break;
        }
        if (value) {
        // console.log('KNCHAT Received', new Date() - startTime, value);
        // expected format of value is "data: value\n\ndata: value\n\ndata: value"
          const chunks = value.split('\n\n').filter(c => c);
          // capture combined tool output
          if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
            if(combinedToolOutputToolCount < CONFIG.AGENTS_TOOL_GROUPS.COMBINED_TOOL_GROUPS_COUNT) {

              chunks.forEach((chnk) => {
                if (chnk.includes(CONFIG.AGENTS_TOOL_GROUPS.COMBINED_TOOL[0].TOOL_FULLNAME) || chnk.includes(CONFIG.AGENTS_TOOL_GROUPS.COMBINED_TOOL[1].TOOL_FULLNAME)) {
                  combinedToolOutput = '';
                  processingCombinedToolOutput = true;
                  combinedToolOutput = chnk.replace('data:', '').trim();
                } else if(processingCombinedToolOutput && !chnk.includes('data:')) {
                  combinedToolOutput += chnk;
                } else {
                  processingCombinedToolOutput = false;
                }
              });

              if(processingCombinedToolOutput) {
                continue;
              }
            }
            // capture combined tool output ends

            // process the aggregated output
            if(!processingCombinedToolOutput && combinedToolOutput) {

              let parsedCombinedToolOutput = {};
              try{
                parsedCombinedToolOutput = JSON.parse(combinedToolOutput);
              }catch(e) {
                console.log(`KNCHAT: failed parsing combinedToolOutput; ${combinedToolOutput}; ${requestId}`);
              }

              Object.keys(CONFIG.AGENTS_TOOL_GROUPS).some((key) => {
                if(parsedCombinedToolOutput?.tool === CONFIG.AGENTS_TOOL_GROUPS[key].TOOL_FULLNAME) {
                  parsedCombinedToolOutput.tool = CONFIG.AGENTS_TOOL_GROUPS[key].TOOL;
                  return true;
                }
              });
              aggregatedCombinedToolOutput[parsedCombinedToolOutput?.tool] = parsedCombinedToolOutput?.tool_data?.raw_output;

              if (parsedCombinedToolOutput?.tool === CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL) {
                rawSearchResults = Object.values(parsedCombinedToolOutput?.tool_data?.raw_output[0] ?? {}).reduce((acc, output) => {
                  const kpCmsId = output?.kp_cms_id;
                  if (kpCmsId) {
                    acc.push({ id: kpCmsId, slide: output?.page });
                  }
                  return acc;
                }, []) ?? [];
                if (rawSearchResults.length > 0) {
                  searchResultDocs = await getSearchResults(rawSearchResults);
                }
              }

              combinedToolOutput = '';
              combinedToolOutputToolCount++;
            }
            // process the aggregated output ends
          }

          let content = null;
          chunks.forEach(chunk => {
            chunkCnt++;
            // expected format of chunk is "data: value"
            const data = chunk.replace('data:', '').trim();
            // console.log('KNCHAT chunk', data);

            let json = {};
            try {
              json = data ? JSON.parse(data) : {};

              // we receive many chunks, but only chunks with choices[0].delta.content are chat messages
              if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
                //process is_contributor for KN Materials tool
                if(json.tool === CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL_FULLNAME && json.message_text === CONFIG.AGENTS_TOOL_GROUPS.KN.CONTRIBUTION_COMPLETED_TEXT && !forcedToolSelection.length) {
                  if(getState().chat.selectedTool?.[0] === CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL_FULLNAME && !json?.tool_data?.raw_output?.[0]?.is_contributor) {
                    console.info('KNCHAT: KN Materials decided not to contribute, trying Experts');
                    dispatch(abortFetch());
                    dispatch(streamChat(selectedEngine, onChunk, retry, sendMessageAnalytics, [CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL_FULLNAME]));
                    return;
                  }
                }
                //process is_contributor for Experts tool
                if(json.tool === CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL_FULLNAME && json.message_text === CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.CONTRIBUTION_COMPLETED_TEXT) {
                  if(forcedToolSelection.length && !json?.tool_data?.raw_output?.[0]?.is_contributor) {
                    console.info('KNCHAT: Experts also decided not to contribute, reverting to KN Materials');
                    dispatch(abortFetch());
                    dispatch(streamChat(selectedEngine, onChunk, retry, sendMessageAnalytics, [CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL_FULLNAME]));
                    return;
                  }
                }

                content = (json?.tool_data?.text_output?.length > 0 && toolGroups.includes(json.tool) && json?.message_type == 'output') ? json.tool_data.text_output[0]?.text : null;
                currentToolGroup = json.tool;

                if (!chatHistoryId && json?.chat_history_id) {
                  chatHistoryId = json.chat_history_id;
                  dispatch({ type: CHAT_SET_HISTORY_ID, payload: chatHistoryId });
                  dispatch(chatHistoryActions.updateChatHistoryID());
                }
              } else {
                content = json?.choices?.length > 0 ? json.choices[0].delta?.content : null;

                if (!chatId && json?.chat) {
                  chatId = json.id;
                }
              }
            } catch (ex) {
              console.info(`KNCHAT failed to parse json. Attempting regex. value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);            // attempt to get content using regex
              let matches;
              if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
                matches = ['', ''];

                const toolsMatches = data.match(/"tool":\s*"([^"]*)"/i);
                const messageTypeMatches = data.match(/"message_type":\s*"([^"]*)"/i);
                if (messageTypeMatches?.length >= 1 &&  toolsMatches?.length > 1 && toolGroups.includes(toolsMatches[1]) && messageTypeMatches[1] === 'output') {
                  currentToolGroup = toolsMatches[1];
                  const textMatches = data.match(/"text":\s*"([^"]*)"/i);
                  if (textMatches?.length >= 1){
                    matches = textMatches;
                  }
                }

              } else {
                matches = data.match(/"content":\s*"([^"]*)"/i);
              }

              if (matches?.length >= 1) {
                content = matches[1];
              } else {
                if (retry) {
                  dispatch({ type: NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
                  analytics.naviErrorAnalytics(ex, chatId, requestId, getState().chat.chatHistoryId);
                  throw new Error(`KNCHAT Second attempt failed to find content in chunk. error:${ex}; data:${data}; matches:${matches}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
                } else {
                  console.error(`KNCHAT failed to find content in chunk. Retrying. error:${ex}; data:${data}; matches:${matches}; value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);

                  // stop this API call and let's try again
                  content = null;
                  processChunks = false;
                  endedCleanly = true; // yes really, if false it'll trigger an error which isnt accurate
                  shouldProcessMessage = false;
                  dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: 'I\'ve hit a snag - continuing in a moment.', role: ROLES.RETRY, processed: true, requestId } });
                  analytics.naviErrorAnalytics(ex, chatId, requestId, getState().chat.chatHistoryId);
                  console.log('KNCHAT run again');
                  fetchController.abort('trying again');
                  return dispatch(streamChat(selectedEngine, onChunk, true));
                }
              }
            }

            if (content) {

              if (contentCnt === 0) {
                const timeToFirstChunk = new Date() - startTime;
                console.warn(`KNCHAT time to first chunk ${timeToFirstChunk}ms; requestId: ${requestId}; chatId: ${chatId};`);
                dispatch({ type: TIME_TO_FIRST_CHUNK, payload: { time: timeToFirstChunk}});
              }
              let newContent = content;
              rawContent += newContent;

              newContent = newContent.replace(/\n/g, '<br/>');

              //bold markup handling
              if (newContent.match(/\*{1,2}/ig)) {
                // has a *, is it mardown for bold **
                boldMarkdown += content;
                waitForBoldMarkdown = true;
              } else if (waitForBoldMarkdown) {
                boldMarkdown += content;
                if (boldMarkdown.match(/\*\*/ig)) {
                  if (!boldMarkdownOpen) {
                    newContent = boldMarkdown.replace(/\*\*/ig, '<b>');
                    boldMarkdownOpen = true;
                  }
                  else {
                    newContent = boldMarkdown.replace(/\*\*/ig, '</b>');
                    boldMarkdownOpen = false;
                  }
                  waitForBoldMarkdown = false;
                  boldMarkdown = '';
                }
              }

              //custom markdown handling [1-Experts] [1-KN Materials] [1-Exa AI]
              let key = '';
              if (newContent.match(/\[/ig)) {
                customMarkdown += content;
                waitForCustomMarkdown = true;
              } else if (waitForCustomMarkdown) {
                customMarkdown += content;
                const customMarkdownMatches = customMarkdown.match(customMarkdownRegex);

                if (customMarkdownMatches) {
                  [newContent, key] = processCustomMarkdown(customMarkdownMatches[0], customMarkdown, aggregatedCombinedToolOutput[currentToolGroup]?.[0], currentToolGroup);
                  keys.push(key);
                  //handle consecutive occurance of [1-Experts] [1-KN Materials] [1-Exa AI]
                  customMarkdown = newContent;
                  if (!customMarkdown.includes('[')) {
                    waitForCustomMarkdown = false;
                    customMarkdown = '';
                  }
                } else if (newContent.includes(']')) { //retain non-matching custom markdown
                  newContent = customMarkdown;
                  waitForCustomMarkdown = false;
                  customMarkdown = '';
                }
              }

              if (!waitForBoldMarkdown && !waitForCustomMarkdown) {
                if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
                  if (toolGroupsStatus?.[currentToolGroup].isFirstMessage) {
                    console.log('aggregatedCombinedToolOutput', aggregatedCombinedToolOutput[currentToolGroup]);
                  
                    let combinedToolOutput = {};
                    if(currentToolGroup === CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL) {
                      combinedToolOutput = aggregatedCombinedToolOutput[currentToolGroup];
                    }else {
                      combinedToolOutput = aggregatedCombinedToolOutput[currentToolGroup]?.[0] || {};
                    }

                    dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { role: ROLES.ASSISTANT, content: newContent, processed: false, requestId, toolGroup: currentToolGroup, combinedToolOutput } });
                    currentToolGroup === CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL && dispatch({ type: UPDATE_SEARCH_RESULTS, payload: searchResultDocs });
                    toolGroupsStatus[currentToolGroup].isFirstMessage = false;
                  
                    //prepare citations
                    shouldProcessMessage = false;
                    const messages = getState().chat.messages;
                    const message = messages[messages.length - 1];
                    (async () => {
                      dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: [], loading: true } });
                      const processedMessage = await processMessage(message, requestId, getState().chat.query);
                      dispatch({ type: CHAT_SET_MESSAGE_SOURCES, payload: processedMessage.sources });
                      keys?.forEach(key => dispatch(findSource(processedMessage, key)));
                      keys = [];
                    })();
                  } else {
                    const messages = getState().chat.messages;
                    const message = messages[messages.length - 1];
                    keys?.forEach(key => dispatch(findSource(message, key)));
                    keys = [];
                    dispatch({ type: CHAT_STREAM_CHUNK, payload: { content: newContent, toolGroup: currentToolGroup } });
                    onChunk && onChunk();
                  }
                } else {
                  if (!createdNewMessage) {
                    dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { role: ROLES.ASSISTANT, content: newContent, processed: false, requestId } });
                    createdNewMessage = true;
                  } else {
                    dispatch({ type: CHAT_STREAM_CHUNK, payload: { content: newContent } });
                    onChunk && onChunk();
                  }
                }
              }
              contentCnt++;
            }
            {
              CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE ?
                (json?.message_type === 'status') && (() => {
                  let styledStatusMsg = '';
                  if (json?.tool === CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL_FULLNAME) {
                    styledStatusMsg = CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.STATUS_MESSAGE;
                  } else {
                    styledStatusMsg = handleStyledStatusMessages(json.message_text);
                  }
                  dispatch({
                    type: CHAT_STREAM_NEW_MESSAGE,
                    payload: {
                      content: styledStatusMsg,
                      role: ROLES.STATUS,
                      processed: true,
                      requestId
                    }
                  });
                  onChunk && onChunk();
                })()
                :
                (json?.user_message) && (() => {
                  console.log(`KNCHAT message: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
                  const styledStatusMsg = handleStyledStatusMessages(json.user_message);
                  dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: styledStatusMsg, role: ROLES.STATUS, processed: true, requestId } });
                  onChunk && onChunk();
                  createdNewMessage = false;
                })();
            }
            if (json?.system_message) {
              switch (json.system_message) {
                case 'usage':
                  console.warn(`KNCHAT usage: ${JSON.stringify(json.usage)}; requestId: ${requestId}; chatId: ${chatId};`);
                  break;
                case 'END CHAT':
                  processChunks = false;
                  endedCleanly = true;
                  dispatch({ type: CHAT_SET_HISTORY_ID, payload: json.chat_history_id });
                  !CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && dispatch(chatHistoryActions.updateChatTitle(json?.chat_title));
                  dispatch({ type: CHAT_STREAM_COMPLETED, payload: true });
                  dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
                  // this stream closes here for long messages with sources
                  break;
                case 'START INNER CHAT':
                  dispatch({ type: CHAT_SET_HISTORY_ID, payload: json.chat_history_id });
                  !CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && json.chat_title && dispatch(chatHistoryActions.updateChatTitle(json?.chat_title));
                  dispatch(chatHistoryActions.updateChatHistoryID());
                  break;
                default:
                  break;
              }
              console.warn(`KNCHAT system message: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
              if (json?.error) {
                shouldProcessMessage = false;
                dispatch({ type: NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
                analytics.naviErrorAnalytics(json?.error, chatId, requestId, getState().chat.chatHistoryId);
                console.error(`KNCHAT system error: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
              }
            }
          });
        }
      }

      console.log('KNCHAT rawContent', rawContent);
      if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
        processChunks = false;
        endedCleanly = true;
        dispatch({ type: CHAT_STREAM_COMPLETED, payload: true });
        dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
        dispatch({type: END_CHAT});
      }

      if (!endedCleanly) {
        await dispatch({ type: CHAT_STREAM_COMPLETED, payload: false });
        console.warn(`KNCHAT ended prematurely. chatId: ${chatId}; chunkCnt: ${chunkCnt}; contentCnt: ${contentCnt};`);
      }

      if (shouldProcessMessage) {
        const unprocessedMessages = getState().chat.messages.filter(m => !m.processed);
        unprocessedMessages.forEach(message => {
          const processedMessage = processMessage(message, requestId, getState().chat.query);
          dispatch(getSourcesFromSearch(processedMessage, requestId));
          dispatch({ type: CHAT_REPLACE_MESSAGE, payload: processedMessage });
        });
      }
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('KNCHAT callChat: API call aborted', error, 'requestId:', requestId);
    } else {
      console.error('KNCHAT callChat: API call failed', error, 'requestId:', requestId);
      analytics.naviErrorAnalytics(error.message, '', requestId, getState().chat.chatHistoryId);
      dispatch({ type: CHAT_FAILURE, payload: error });
      // dispatch({ type: RESET_SELECTED_TOOLS });
      return null;
    }
  }
};

const resetChatState = () => (dispatch) => {
  dispatch({ type: RESET_CHAT_STATE });
};

const processMessage = async (message, requestId, query = '') => {
  // console.log('KNCHAT completed. Usage:', data.usage);
  const sources = [];
  let docid = '';

  const parsedMessage = processMessageForMarkdown(message);

  if([CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL, CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL].includes(parsedMessage?.toolGroup)) {
    return {
      ...parsedMessage,
      sources: [],
      requestId
    };
  }

  if(parsedMessage?.combinedToolOutput) {
    Object.keys(parsedMessage?.combinedToolOutput).forEach((key, index) => {

      if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg' && index < qaKpCmsIds.length && parsedMessage?.toolGroup === CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL) {
        console.info(`KNCHAT requestId ${requestId} swapped ${docid} (prod) for ${qaKpCmsIds.reverse()[index]} (QA)`);
        docid = qaKpCmsIds.reverse()[index];
      }else {
        docid = parsedMessage.combinedToolOutput[key]?.kp_cms_id;
      }

      const sourceId = key?.split('-')?.[0] || 0;
      sources.push({
        slide: parsedMessage.combinedToolOutput[key]?.page,
        sourceId,
        id: docid,
      });
    });
  } else {

    if (parsedMessage.role === 'function') return null;

    const slideNumberRegex = /slideno=(\d+)/g;
    const kpCmsIdRegex = /\/kp\/([a-f0-9-]+)/g;
    const anchorTextRegex = /<a[^>]*>([^<]+)<\/a>/g;

    if (parsedMessage?.content?.match(kpCmsIdRegex)) {
      let matchSlideNumber, matchKpCmsId, matchSourceId;
      let cnt = 0;
      while ((matchSlideNumber = slideNumberRegex.exec(parsedMessage.content)) && (matchKpCmsId = kpCmsIdRegex.exec(parsedMessage.content)) && (matchSourceId = anchorTextRegex.exec(parsedMessage.content))) {
        try {
          const slide = matchSlideNumber[1];
          let docid = matchKpCmsId[1];
          let sourceId = matchSourceId[1];

          if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg' && cnt < qaKpCmsIds.length && !isUsingAgentsinStaging) {
            console.info(`KNCHAT requestId ${requestId} swapped ${docid} (prod) for ${qaKpCmsIds.reverse()[cnt++]} (QA)`);
            docid = qaKpCmsIds.reverse()[cnt++];
          }

          sources.push({
            slide,
            sourceId,
            id: docid,
          });


        } catch (ex) {
          console.error(`KNCHAT requestId ${requestId} Failed to parse sources in content`, ex);
          console.error(`KNCHAT  requestId ${requestId} content`, parsedMessage.content);
          return null;
        }
      }
    }
  }

  const uniqueSources = sources.reduce((acc, current) => acc.some(o => o.id === current.id && o.slide === current.slide) ? acc : [...acc, current], []);
  const verifiedSources = await getSourcesFromSearch(uniqueSources, requestId, query);

  return {
    ...parsedMessage,
    sources: verifiedSources,
    requestId
  };
};

const getSourcesFromSearch = async (messageSources, requestId, searchQuery) => {
  let sources = [];

  try{
    if (messageSources?.length) {
      const query = messageSources.map(source => {
        if (source?.id?.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i)) {
          return `kp_cms_id:${source.id}`;
        }
        console.error('KNCHAT getSourcesFromSearch: Invalid source id provided:', source?.id, 'message:', 'requestId:', requestId);
        return null;
      }).filter(s => !!s).join(' OR ');

      console.warn('KNCHAT getSourcesFromSearch in env', CONFIG.ENVIRONMENT, 'query', query, 'requestId:', requestId);

      if (query) {
        let data;
        let modifiedResults;
        // This change is only for staging ENV to test Agents tool sources/docviz
        if (isUsingAgentsinStaging) {
          const sourceIds = messageSources.map(source => source.id);
          data = await axios.post(CONFIG.API_URL.GENAI_RETRIEVAL,
            {
              'query': searchQuery || 'a',
              'method': 'hybrid_1',
              'top_n': 100,
              'consumer_options': {
                'consumer_key': 'KN',
                'data_source': 'materials'
              },
              'request_id': getRandomString(20),
              'document_id_filter': {
                'kp_cms_id': sourceIds,
                'filter_mode': 'include'
              }
            },
            {
              headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
              }
            }
          );
          //results modification needed for docviz component to consume these keys
          modifiedResults = data.results?.map((result) => {
            const dateRevised = result?.date ? new Date(`${result.date}T00:00:00`) : null;

            let kpCmsId = result.kp_cms_id;
            const newResult = {
              ...result,
              id: kpCmsId,
              docId: kpCmsId,
              kbCmsId: '',
              kpCmsId: kpCmsId,
              docRank: result.rank,
              docType: result.material_type,
              fileName: result.filename,
              materialDesc: result.desc,
              resultType: '',
              materialUrl: '',
              dateRevised: dateRevised,
              globalRelevance: result.relevance,
              materialId: 0,
              hasHtmlPreview: true,
              hasImagePreview: true,
              relevantSlidePath: result.image_path,
              paRecommended: false,
              openAccessInd: true,
              allSubjects: result.subject_json ? JSON.parse(`[${result.subject_json}]`).map((subject) => ({
                attachmentId: '',
                fullPath: subject.path?.replace(/>/g, '/') || '',
                hierarchyLevel: 0,
                kpCmsId: '',
                parentId: subject.Parent_ID || '',
                subjectId: subject.SUBJECT_ID || '',
                subjectName: subject.SUBJECT_NAME || '',
                urlId: '',
              })) : [],
              allAuthors: result.author_json ? JSON.parse(`[${result.author_json}]`).map((author) => ({
                additionalContact: (author.IsAddtionalContact === 'Y'),
                email: author.Email || '',
                firstName: author.firstname || '',
                hrEmployeeId: author.HR_emp_id || '',
                lastName: author.lastname || '',
                role: '',
                roleSortOrder: author.positionSortOrder || 0,
                staffId: 0,
              })) : [],
              functionalAllPAs: result.fpa_json ? JSON.parse(`[${result.fpa_json}]`).map((fpa) => ({
                fullPath: fpa.path?.replace(/>/g, '/') || '',
                hiearchyLevel: 0,
                keywordTopic: fpa.keywordTopic || '',
                owner: (fpa.isOwner === 'true'),
                paRecommended: fpa.ISPARECOMMENDED || false,
                parentId: fpa.Parent_ID || '',
                topicId: fpa.Topic_ID || '',
                topicNameAlias: fpa.Topic_Name_Alias || '',
              })) : [],
              industryAllPAs: result.ipa_json ? JSON.parse(`[${result.ipa_json}]`).map((ipa) => ({
                fullPath: ipa.path?.replace(/>/g, '/') || '',
                hiearchyLevel: 0,
                keywordTopic: ipa.keywordTopic || '',
                owner: (ipa.isOwner === 'true'),
                paRecommended: ipa.ISPARECOMMENDED || false,
                parentId: ipa.Parent_ID || '',
                topicId: ipa.Topic_ID || '',
                topicNameAlias: ipa.Topic_Name_Alias || '',
              })) : [],
            };
    
            delete newResult.kp_cms_id;
            delete newResult.filename;
            delete newResult.rank;
            delete newResult.material_type;
            delete newResult.date;
            delete newResult.relevance;
            delete newResult.author_json;
            delete newResult.subject_json;
            delete newResult.fpa_json;
            delete newResult.ipa_json;
    
            return newResult;
          });
        } else {
          data = await axios.get(CONFIG.API_URL.MATERIAL(query));
        }

        sources = messageSources.map(source => {
          const doc = isUsingAgentsinStaging ? modifiedResults?.find(doc => doc.kpCmsId === source.id) : data.doc?.find(doc => doc.kpCmsId === source.id);
          if (doc) {
            return {
              ...doc,
              id: doc.kpCmsId,
              page: source.slide || 1,
              sourceId: source?.sourceId,
            };
          } else {
            console.error('KNCHAT getSourcesFromSearch: Failed to find document, doc:', 'sourceid:', source.id, 'requestId:', requestId);
            return null;
          }
        }).filter(source => !!source);
        if (sources.length <= 0) {
          console.error('KNCHAT getSourcesFromSearch: Source documents not found in search', 'requestId:', requestId);
        }
      } else {
        console.error('KNCHAT getSourcesFromSearch: Sources do not have valid IDs', 'requestId:', requestId);
      }
    } else {
      console.info('KNCHAT getSourcesFromSearch: No sources found', 'requestId:', requestId);
    }
  } catch (ex) {
    console.error('KNCHAT getSourcesFromSearch: Error getting sources', ex, 'requestId:', requestId);
  }

  return sources;
};

const findSource = (message, key) => async (dispatch, getState) => {
  if ( [ROLES.USER, ROLES.STATUS].includes(message?.role) || message?.toolGroup === CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL) return null;

  if (message?.toolGroup === CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL) {
    dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: [], loading: true } });

    if(CONFIG.FEATURE_TOGGLES.SHOW_WEBSEARCH_SOURCES) {
      //fix ordering of sources
      let matchedUrls = [];
      let matchedValues = [];

      if (message?.content) {
        matchedUrls = message?.content?.match(/<a[^>]*>([^<]+)<\/a>/ig) || [];
        matchedValues = matchedUrls?.map((url) => {
          return {
            url: url.match(/((http|https):[^"]*)/ig)?.[0],
            text: url.match(/>([^<]+)/ig)?.[0].replace('>', ''),
          };
        }) || [];
      }

      if (matchedUrls.length && matchedValues.length) {
        message?.combinedToolOutput.forEach((output, index) => {
          const matchedUrl = matchedValues.find((obj) => obj.url === output.url);
          if (matchedUrl) {
            message.combinedToolOutput[index].bulletNum = parseInt(matchedUrl?.text) || 0;
          }
        });

        message.combinedToolOutput?.sort((a, b) => a.bulletNum - b.bulletNum);
      } else {
        message.combinedToolOutput = [];
      }
    } else {
      message.combinedToolOutput = [];
    }

    dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: message.combinedToolOutput, loading: false } });
    return null;
  }

  if (message?.sources?.length) {
    const sourceId = key?.split('-')?.[0] || 0;
    const source = message?.sources?.find(src => src.sourceId == sourceId);
    let messageSources = getState().chat.messageSources?.[message.id]?.sources || [];

    const sourceExists = messageSources?.find((src) => src.sourceId == sourceId);

    if(!sourceExists && source) {
      messageSources = [...messageSources, source];
    }

    dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: messageSources, loading: false } });
  }
};

const getSearchResults = async(resultsSet) => {
  const reversedQaKpCmsIds = [...qaKpCmsIds].reverse();
  try {
    if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg') {
      for (let i = 0; i < resultsSet.length && i < reversedQaKpCmsIds.length; i++) {
        console.info(`KNCHAT swapped Search Result kp_cms_id: ${resultsSet[i]} (prod) for ${reversedQaKpCmsIds[i]} (QA)`);
        resultsSet[i].id = reversedQaKpCmsIds[i];
      }
    }

    const query = resultsSet.map(result => {
      if (result?.id?.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i)) {
        return `kp_cms_id:${result?.id}`;
      }
      console.error('KNCHAT getKNResultsFromSearch: Invalid source id provided:', result?.id);
      return null;
    }).filter(s => !!s).join(' OR ');

    const data = await axios.get(CONFIG.API_URL.MATERIAL(query));
    const searchResults = resultsSet?.map(result => {
      const doc = data.doc?.find(doc => doc.kpCmsId === result.id);
      if (doc) {
        return {
          ...doc,
          id: doc.kpCmsId,
          page: `${result.slide || 1}`,
          currentSlideIndex: result?.slide - 1,
        };
      } else {
        console.error('KNCHAT getKNresultsFromSearch: Failed to find document, doc:', result.id);
        return null;
      }
    }).filter(source => !!source);

    if (searchResults.length > 0) {
      return searchResults;
    } else {
      console.error('KNCHAT getSearchResultsFromSearch: Message had results but no documents found in search');
      return [];
    }
  } catch (ex) {
    console.error('KNCHAT getSearchResults: Error getting search results', ex);
    return [];
  }
};

const setChatHistoryId = (chatHistoryId) => (dispatch) => {
  dispatch({ type: CHAT_SET_HISTORY_ID, payload: chatHistoryId });
};

const setRequestID = (requestId) => (dispatch) => {
  dispatch({ type: CHAT_SET_REQUEST_ID, payload: requestId });
};

const setQuery = (query) => (dispatch) => {
  dispatch({ type: CHAT_SET_QUERY, payload: query });
};

const addMessage = (message) => (dispatch) => {
  dispatch({ type: CHAT_ADD_MESSAGE, payload: message });
};

const abortFetch = () => (dispatch, getState) => {
  const fetchController = getState().chat.fetchController;
  if (fetchController !== null) {
    fetchController.abort('User clicked stop generating');
  }
  dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
  dispatch({ type: REMOVE_FETCH_CONTROLLER });
};

const setSelectedEngine = (engine) => (dispatch) => {
  dispatch({ type: SET_SELECTED_ENGINE, payload: engine });
};

const startNewChat = () => (dispatch) => {
  dispatch({ type: START_NEW_CHAT });
};

const updateChatMessages = (updatedMessages) => (dispatch) => {
  dispatch({ type: SET_CHAT_HISTORY_MESSAGE, payload: updatedMessages, loading: true });
};

const toggleWebSources = () => ({
  type: TOGGLE_WEB_SOURCES
});

const updateWebFilters = (startDate, endDate, filterName, domains = []) => (dispatch) => {
  dispatch({ type: UPDATE_WEB_FILTERS, payload: { startDate, endDate, filterName, domains } });
};

const clearWebFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_WEB_FILTERS });
};

const setActiveTool = (tool) => (dispatch) => {
  dispatch({ type: SET_SELECTED_TOOL, payload: tool });
};

const resetSelectedTool = () => (dispatch) => {
  dispatch({ type: RESET_SELECTED_TOOL });
};

export const actions = {
  streamChat,
  setHasSentInitialMessage,
  setChatMessageSources,
  resetChatState,
  setQuery,
  addMessage,
  abortFetch,
  setSelectedEngine,
  setChatHistoryId,
  startNewChat,
  processMessage,
  getSourcesFromSearch,
  updateChatMessages,
  getSearchResults,
  toggleWebSources,
  updateWebFilters,
  clearWebFilters,
  setActiveTool,
  resetSelectedTool,
  findSource
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case END_CHAT:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messageSources: (() => {
          const updatedSources = {};
          Object.keys(state.messageSources).forEach((key) => {
            updatedSources[key] = {...state.messageSources[key], loading: false };
          });

          return updatedSources;
        })()
      };
    case CHAT_REPLACE_MESSAGE:
      const newMessages = state.messages.map(m => {
        if (m.id === action.payload.id) {
          return action.payload;
        }
        return m;
      });
      console.log('KNCHAT replace message', action.payload, 'newMessages', newMessages);
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: newMessages
      };
    case CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
      };
    case SET_FETCH_CONTROLLER:
      return {
        ...state,
        fetchController: action.payload
      };
    case REMOVE_FETCH_CONTROLLER:
      return {
        ...state,
        loading: false,
        fetchController: null
      };
    case RESET_CHAT_STATE:
      if (state.fetchController !== null) {
        state.fetchController.abort('User cancelled/reset chat');
      }
      return initialState;
    case CHAT_ADD_MESSAGE:
      const replaceLastMessage = () => {
        const messagesCopy = [...state.messages];
        messagesCopy[messagesCopy.length - 1] = action.payload;
        return messagesCopy;
      };

      const isUser = action.payload.role === ROLES.USER;
      const isLastMessageAnError = state.messages[state.messages.length - 1]?.role === ROLES.ERROR;
      const id = state.messages.length > 1 ? state.messages[state.messages.length - 1].id + 1 : 0;
      return {
        ...state,
        messages: isUser && isLastMessageAnError
          ? replaceLastMessage()
          : [...state.messages, { ...action.payload, id }],
      };
    case CHAT_DELETE_LATEST_ASSISTANT_MESSAGE:
      const deleteLatestAssistantMessage = () => {
        const _messages = [...state.messages];
        for (let i = _messages.length - 1; i >= 0; i--) {
          if (_messages[i]?.role === ROLES.ASSISTANT) {
            _messages.splice(i, 1);
            break;
          }
        }
        return _messages;
      };
      return {
        ...state,
        messages: deleteLatestAssistantMessage()
      };
    case SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS:
      return {
        ...state,
        hasSentInitialMessage: action.payload,
      };
    case CHAT_STREAM_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, { ...action.payload, id: state.messages[state.messages.length - 1].id + 1 }],
      };
    case UPDATE_STATUS_MESSAGES:
      return {
        ...state,
        statusMessages: {
          ...state.statusMessages,
          [action.payload.toolGroup]: [
            ...(state.statusMessages[action.payload.toolGroup] || []),
            action.payload.content
          ]
        }
      };
    case RESET_STATUS_MESSAGES:
      return {
        ...state,
        statusMessages: {}
      };
    case NEW_ERROR_STATUS:
      return {
        ...state,
        loading: false,
        messages: [
          ...state.messages,
          action.payload
        ]
      };
    case TIME_TO_FIRST_CHUNK:
      return {
        ...state,
        timeToFirstChunk: action.payload.time
      };
    case UPDATE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: { ...state.searchResults, [state.messages[state.messages.length - 1].id]: action.payload }
      };
    case CHAT_STREAM_CHUNK:
      const appendToLastAssistantMessage = () => {
        let lastAssistantMessage = null;
        const reversedMessages = [...state.messages].reverse();
        if (CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE) {
          lastAssistantMessage = reversedMessages.find(m => m.role === ROLES.ASSISTANT && m.toolGroup === action.payload.toolGroup);
        } else {
          lastAssistantMessage = reversedMessages.find(m => m.role === ROLES.ASSISTANT);
        }
        if (lastAssistantMessage) {
          const indexToUpdate = reversedMessages.findIndex(m => m === lastAssistantMessage);
          reversedMessages[indexToUpdate].content += action.payload.content;
        }
        return reversedMessages.reverse();
      };

      return {
        ...state,
        isChatStreaming: true,
        messages: appendToLastAssistantMessage(),
        ...(CONFIG.FEATURE_TOGGLES.USE_AGENT_CHAT_SERVICE && { toolGroup: action.payload.toolGroup })
      };
    case CHAT_STREAM_COMPLETED:
      return {
        ...state,
        isChatCompleted: action.payload,
      };
    case CHAT_STREAM_CLOSED:
      return {
        ...state,
        isChatStreaming: false,
        isChatStreamClosed: action.payload,
      };
    case CHAT_SET_MESSAGE_SOURCES:
      const appendMessageSources = () => {
        const messages = [...state.messages];
        const lastAssistantMessage = messages[messages.length - 1];
        if (lastAssistantMessage) {
          lastAssistantMessage.sources = action.payload;
        }
        return messages;
      };
      return {
        ...state,
        messages: appendMessageSources()
      };
    case CHAT_SOURCES_SUCCESS:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.id]: {
            sources: action.payload.sources,
            loading: action.payload.loading,
            error: false,
            errorMessage: ''
          }
        }
      };
    case CHAT_SOURCES_FAILURE:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.id]: {
            sources: state.messageSources[action.payload.id]?.sources,
            loading: false,
            error: true,
            errorMessage: action.payload.error
          }
        }
      };
    case SET_SELECTED_ENGINE:
      return {
        ...state,
        selectedEngine: action.payload
      };
    case CHAT_SET_QUERY:
      return {
        ...state,
        query: action.payload
      };
    case START_NEW_CHAT:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [],
        messageSources: {},
        query: '',
      };
    case CHAT_SET_HISTORY_ID:
      return {
        ...state,
        chatHistoryId: action.payload
      };
    case CHAT_SET_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload
      };
    case SET_CHAT_HISTORY_MESSAGE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [...state.messages, ...action.payload.map(message => ({ ...message, id: message.id }))],
      };
    case TOGGLE_WEB_SOURCES:
      const isWebSourcesActive = state.selectedTool.includes(CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH);
      return {
        ...state,
        selectedTool: isWebSourcesActive 
          ? [CONFIG.NAVI_TOOL_NAMES.KNOWLEDGE] 
          : [CONFIG.NAVI_TOOL_NAMES.WEB_SEARCH],
        loading: false
      };
    case UPDATE_WEB_FILTERS:
      return {
        ...state,
        dateFilters: { ...state.dateFilters, 
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          filterName: action.payload.filterName
        },
        domains: action.payload.domains,
        loading: false
      };
    case CLEAR_WEB_FILTERS:  
      return {
        ...state,
        dateFilters: {
          startDate: '',
          endDate: '',
          filterName: ''
        },
        domains: [],
        loading: false
      };

    case SET_SELECTED_TOOL : 
      return {
        ...state,
        selectedTool: Array.isArray(action.payload) ? action.payload : [action.payload]
      };

    case RESET_SELECTED_TOOL:
      return {
        ...state, 
        selectedTool: [CONFIG.NAVI_TOOL_NAMES.KNOWLEDGE, CONFIG.NAVI_TOOL_NAMES.EXPERTS]
      };
      
    default:
      return state;
  }
};